import {
  Create,
  SimpleForm,
  DateInput,
  SelectInput,
  useCreate,
  useUpdate,
  useRedirect,
  useDataProvider
} from 'react-admin';
import PatientInput from "../patient/PatientInput";
import ProviderPractitionerInput from "../providerpractitioner/ProviderPractitionerInput";

export const ReconciliationReportCreate = () => {
  const [create, { isLoadingC, errorC }] = useCreate();
  const [update, { data, isLoadingU, errorU }] = useUpdate();
  const dataProvider = useDataProvider();
  let redirect = useRedirect();

  const validateReportCreation = async (values) => {
    const required_fields = [
      ['patient_id', 'Patient'],
      ['model', 'Model'],
    ];
    const errors = {};
    for (const f of required_fields) {
      if (!values[f[0]]) {
        errors[f[0]] = `${f[1]} is required`;
      }
    }
    if (!!values.patient_id) {
      const {data: reports} = await dataProvider.getList(
        'reconciliation_report',
        {filter: { patient_id: values.patient_id, state: 'draft' }}
      );
      if (reports !== undefined && reports.length > 0) {
        errors.patient_id = 'A reconciliation report with draft state already exists for this member';
      }
    }
    return errors;
  };
  const onSubmit = async (data) => {
    let rr = {
      patient_id: data.patient_id,
      provider_practitioner_id: data.provider_practitioner_id,
      next_appointment_date: data.next_appointment_date,
      model: data.model,
    };
    let r = await create('reconciliation_report', {data:rr}, {returnPromise: true});
    if ((data.reference_name !== undefined) && (data.reference_id !== undefined)) {
      await update(
        data.reference_name,
        { id: data.reference_id, data: {'reconciliation_report_id': r.id} },
        { returnPromise: true }
      );
    }
    redirect('edit', 'reconciliation_report', r.id);
  };
  return (
    <Create>
      <SimpleForm onSubmit={onSubmit} validate={validateReportCreation}>
        <PatientInput source="patient_id" fullWidth />
        <ProviderPractitionerInput fullWidth/>
        <DateInput source='next_appointment_date'/>
        <SelectInput
          source='model'
          defaultValue={'v28'}
          choices={[
            {id: 'v24', name: 'use Model v24'},
            {id: 'v28', name: 'use Model v28'},
          ]}
        />
      </SimpleForm>
    </Create>
  );
}

export default ReconciliationReportCreate;
