import {
  List,
  Datagrid,
  TextField,
  SelectInput,
  ReferenceField,
  ReferenceArrayInput,
  AutocompleteArrayInput, Button, useRecordContext,
} from 'react-admin';
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";
import {Link} from "react-router-dom";
import ClaimIcon from "@mui/icons-material/Assignment";

const generateYearList = (startingYear) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = startingYear; year <= currentYear; year++) {
    years.push({ id: year, name: year.toString() });
  }
  return years;
}

const insuranceFileFilters = [
  <ReferenceArrayInput source="insurance_provider_id" reference="insurance_provider" alwaysOn>
    <AutocompleteArrayInput
      optionText="description"
      filterToQuery={(searchText) => ({ "description@_ilike": searchText })}
    />
  </ReferenceArrayInput>,
  <ReferenceArrayInput source="grouper" reference="file_grouper" alwaysOn>
    <AutocompleteArrayInput
      optionText="description"
      filterToQuery={(searchText) => ({ "description@_ilike": searchText })}
    />
  </ReferenceArrayInput>,
  <ReferenceArrayInput source="file_class" reference="file_class" alwaysOn>
    <AutocompleteArrayInput
      optionText="id"
      filterToQuery={(searchText) => ({ "id@_ilike": searchText })}
    />
  </ReferenceArrayInput>,
  <SelectInput label="Year" source="pe_year" choices={generateYearList(2021)} alwaysOn/>,
];

const GoToDetailButton = (props) => {
  const record = useRecordContext();
  const filter = {
    'insurance_provider_id': record.insurance_provider_id,
    'grouper': record.grouper,
    'file_class': record.file_class,
    'pe_date@_gte': record.pe_year + '-01-01',
    'pe_date@_lte': record.pe_year + '-12-31',
  }
  return (
    <Button label='Year Detail' component={Link}
            to={`/insurance_file?filter=${JSON.stringify(filter)}`}
            startIcon={<ClaimIcon/>}
            {...props}
    />
  );
}

const MissingInsuranceFileList = () => {
  return(
    <List
      perPage={VITE_DEFAULT_LIST_PER_PAGE}
      filters={insuranceFileFilters}
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField source="insurance_provider_id" reference="insurance_provider">
          <TextField source="description" />
        </ReferenceField>
        <TextField source='grouper' />
        <TextField label="Class" source="file_class" />
        <TextField label="Year" source="pe_year" />
        <TextField source="missing_files" />
        <GoToDetailButton />
      </Datagrid>
    </List>
  );
}

export default MissingInsuranceFileList;
