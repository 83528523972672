import {
  Labeled,
  TextField,
  useRecordContext,
  useDataProvider,
} from 'react-admin';
import {useState} from 'react';
import {LinearProgress} from '@mui/material';

export const HccYearScoreField = ({hccYear, labelYear, ...rest}) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [childContent, setChildContent] = useState(null);
  const labelText = `HCC score ${labelYear} (${hccYear})`

  if (record === null) return null;

  if (childContent === null) {
    if (record.id === null) {
      setChildContent('No patient');
    } else {
      dataProvider.getList(
        'patient_risk_score_history',
        {
          filter: {
            patient_id: record.id,
            condition_year_id: hccYear
          },
          sort: { field: 'rpt_pe', order: 'DESC' }
        }
      ).then((response) => {
        if (response.total === 0) {
          setChildContent('No Score');
        } else {
          setChildContent(response['data'][0].hcc_score);
        }
      }).catch((error) => {
        console.log('Error while getting HCC Score', error);
      });
    }
  }
  return childContent === null
    ? <LinearProgress sx={{ width: '25px', marginTop:'12px' }}/>
    : <Labeled label={labelText} ><TextField source="content" record={{content: `${childContent}`}}/></Labeled>;
}

export default HccYearScoreField;
