import {useState} from "react";
import {
  Button,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  Labeled,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  useRecordContext,
  useRefresh,
  Count,
} from "react-admin";
import {FormControlLabel, Grid, Box, FormControl, RadioGroup, Radio, Stack} from "@mui/material";
import PatientEligibilityListField from "../patient/PatientEligibilityListField";
import CollapsibleSection from "../components/CollapsibleSection";
import AcceptedConditionsYearCheckIconField from "./AcceptedConditionsYearCheckIconField";
import {cyToYear, diagnosisConditionsAggFormatter} from "../utils";
import {
  REC_OPEN_DIAGNOSES,
} from "../constants";
import AddIcon from "@mui/icons-material/Add";
import {Link} from "react-router-dom";
import {
  BulkAddClinicalIndicatorQueryButton,
  BulkAddConditionQueryButton,
  ReconciliationReportShowToolbar,
  BulkAddSmartQueryButton
} from "./ReportShowButtons";
import {canEdit} from "./domain";
import ReportStateChipField from "./ReportStateChipField";
import ReconciliationReportTitle from "./ReconciliationReportTitle";
import NoteAvailableReferenceField from "../patient/NoteAvailableReferenceField";
import GatekeeperReferenceField from "../gatekeeper/GatekeeperReferenceField";
import HccYearScoreField from "../patient/HccYearScoreField";
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";


const ReportHeader = () => {
  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item>
          <Labeled label='Humana ID'>
            <ReferenceField reference='patient' source='patient_id' link='show'>
              <TextField source="current_eligibility.id_value"/>
            </ReferenceField>
          </Labeled>
        </Grid>
        <Grid item>
          <ReferenceField reference='patient' source='patient_id' emptyText='Empty' link={false}>
            <Labeled label='Date of Birth'>
              <DateField source="date_of_birth"/>
            </Labeled>
          </ReferenceField>
        </Grid>
        <Grid item>
        <Labeled label='Primary care physician'>
          <GatekeeperReferenceField source='patient.current_eligibility.gatekeeper_id' emptyText='Empty' />
        </Labeled>
        </Grid>
        <Grid item>
          <ReferenceField reference='patient' source='patient_id' emptyText='Empty' link={false}>
            <Labeled label="Line of business">
              <ReferenceField label='Line of business' source="current_eligibility.lob_id" reference="line_of_business">
                <TextField source="description" />
              </ReferenceField>
            </Labeled>
          </ReferenceField>
        </Grid>
        <Grid item>
          <Labeled>
            <ReferenceField label="Eligibility dates" reference='patient' source='patient_id' emptyText='Empty'
                            link={false}>
              <ReferenceManyField reference="patient_eligibility" target="patient_id">
                <PatientEligibilityListField/>
              </ReferenceManyField>
            </ReferenceField>
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled label='Appointment practitioner'>
            <ReferenceField reference='provider_practitioner' source='provider_practitioner_id'>
              <TextField source='name'/>
            </ReferenceField>
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled>
            <DateField source='next_appointment_date'/>
          </Labeled>
        </Grid>
        <Grid item>
          <Labeled label='Member note'>
            <NoteAvailableReferenceField />
          </Labeled>
        </Grid>
        <Grid item>
          <ReportStateChipField label="Report state" size='medium' />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item>
          <ReferenceField reference='patient' source='patient_id' link={false}>
            <HccYearScoreField hccYear={275} labelYear="2024" />
          </ReferenceField>
        </Grid>
        <Grid item>
          <ReferenceField reference='patient' source='patient_id' link={false}>
            <HccYearScoreField hccYear={280} labelYear="2024-2025" />
          </ReferenceField>
        </Grid>
        <Grid item>
          <ReferenceField reference='patient' source='patient_id' link={false}>
            <HccYearScoreField hccYear={285} labelYear="2025" />
          </ReferenceField>
        </Grid>
      </Grid>
    </>
  )
}


export const openRowStyle = (record) => {
  if (record.excluded){
    return {
      backgroundColor: '#999999',
      opacity: '0.5',
    };
  }
  return null
};

const filterValueToList = (filterValue) => {
  let list = [];
  switch (filterValue) {
    case 'open':
      list.push(0);
      list.push(10);
      break;
    case 'billed':
      list.push(12);
      break;
    case 'queried_draft':
      list.push(15);
      break;
    case 'queried_sent':
      list.push(17);
      break;
    case 'queried_answered':
      list.push(18);
      break;
    case 'pending':
      list.push(20);
      break;
    case 'denied':
      list.push(30);
      break;
    case 'accepted':
      list.push(40)
      break;
  }
  return list;
}


const ReconciliationReportShow = () => {
  return (
    <Show title={<ReconciliationReportTitle/>} actions={<ReconciliationReportShowToolbar />}>
      <SimpleShowLayout>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ReportHeader/>
          </Grid>
          <Grid item xs={12}>
            <CollapsibleSection title="Accepted Conditions" initialOpen={true}>
              <AcceptedConditionsPanel/>
            </CollapsibleSection>
          </Grid>
          <Grid item xs={12}>
            <CollapsibleSection title='Past Diagnoses' initialOpen={true}>
              <PastDiagnosisPanel/>
            </CollapsibleSection>
          </Grid>
          <Grid item xs={12}>
            <CollapsibleSection title='Smart Queries' initialOpen={true}>
              <SmartQueryPanel/>
            </CollapsibleSection>
          </Grid>
          <Grid item xs={12}>
            <CollapsibleSection title="Report Queries">
              <QueriesPanel />
            </CollapsibleSection>
          </Grid>
          <Grid item xs={12}>
            <CollapsibleSection title="Hedis">
              <HedisPanel />
            </CollapsibleSection>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};


const AcceptedConditionsPanel = () => {
  const record = useRecordContext();
  const model = record.model;

  return (
    <ReferenceManyField
      label="Accepted"
      reference="rec_accepted_conditions_2"
      source="patient_id"
      target="patient_id"
      filter={{model: model}}
      sort={{field: 'condition_id', order: 'ASC'}}
      perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
      pagination={<Pagination />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="condition_id" label="Condition"/>
        <TextField source="description" />
        <AcceptedConditionsYearCheckIconField label={<div>265<br/>({cyToYear(265)})</div>} conditionYear={265} source='historic_years'/>
        <AcceptedConditionsYearCheckIconField label={<div>275<br/>({cyToYear(275)})</div>} conditionYear={275} source='historic_years'/>
        <AcceptedConditionsYearCheckIconField label={<div>285<br/>({cyToYear(285)})</div>} conditionYear={285} source='historic_years'/>
        <ReferenceField label="Service provider" reference="provider" source="latest_serv_prov" emptyText="N/A">
          <TextField source="name"/>
        </ReferenceField>
      </Datagrid>
    </ReferenceManyField>
  );
};

// Past diagnosis
const PastDiagnosesBulkActions = (props) => (
  <>
    <BulkAddConditionQueryButton refresh={props.refresh}/>
    <BulkAddClinicalIndicatorQueryButton refresh={props.refresh}/>
  </>
);

const RadioLabelWithCounter = (props) => {
  const record = useRecordContext();

  return (
    <span>
      {props.title} (
        {<Count resource={REC_OPEN_DIAGNOSES}
          filter={{ patient_id: record.patient_id, 'current_status_int@_in': filterValueToList(props.status) }} />}
      )
    </span>
  );
}

const PastDiagnosisPanel = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const model = record?.model;
  const [diagnosesFilterValue, setDiagnosesFilterValue] = useState('open');

  const bulkActionButtons = canEdit(record.state) ? <PastDiagnosesBulkActions refresh={refresh}/> : false;

  const handleChangeDiagnosisFilter = (event) => {
    setDiagnosesFilterValue(event.target.value);
  };

  return (
    <>
      <FormControl>
        <RadioGroup row name="row-radio-buttons-group" style={{marginLeft: '20px'}}
                    value={diagnosesFilterValue}
                    onChange={handleChangeDiagnosisFilter}>
          <FormControlLabel value="open" control={<Radio />} label={<RadioLabelWithCounter title="Open" status="open"/>} />
          <FormControlLabel value="billed" control={<Radio />} label={<RadioLabelWithCounter title="Billed" status="billed"/>} />
          <FormControlLabel value="pending" control={<Radio />} label={<RadioLabelWithCounter title="Pending" status="pending"/>} />
          <FormControlLabel value="denied" control={<Radio />} label={<RadioLabelWithCounter title="Denied" status="denied"/>} />
          <FormControlLabel value="accepted" control={<Radio />} label={<RadioLabelWithCounter title="Accepted" status="accepted"/>} />
        </RadioGroup>
      </FormControl>
      <ReferenceManyField
        reference={REC_OPEN_DIAGNOSES}
        source='patient_id'
        target="patient_id"
        filter={{'current_status_int@_in': filterValueToList(diagnosesFilterValue)}}
        // @ts-ignore
        sort={{field: `groupings_${model}`, order: 'ASC'}}
        perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
        pagination={<Pagination />}
      >
        <Datagrid
          bulkActionButtons={bulkActionButtons}
          rowStyle={openRowStyle}
        >
          <TextField source="diagnosis_id" label="Diagnosis"/>
          <TextField source="diag_description" label="Description"/>
          <ReferenceField reference="diagnosis_conditions_agg" source="diagnosis_id" label={<div>HCC<br/>v24/v28</div>}>
            <FunctionField render={(record) => diagnosisConditionsAggFormatter(record)}/>
          </ReferenceField>
          <ReferenceField label="Service provider" reference="provider" source="latest_serv_prov" emptyText="N/A">
            <TextField source="name"/>
          </ReferenceField>
          <DateField label="Last date of service" source='latest_serv_date'/>
        </Datagrid>
      </ReferenceManyField>
    </>
  )
}

// Smart queries
const SmartQueriesBulkActions = (props) => (
  <>
    <BulkAddSmartQueryButton refresh={props.refresh}/>
  </>
);

const SmartQueryPanel = () => {
  const record = useRecordContext();
  const refresh = useRefresh();

  const bulkActionButtons = canEdit(record.state) ? <SmartQueriesBulkActions refresh={refresh}/> : false;

  return (
    <ReferenceManyField
      reference='smart_query'
      source='patient_id'
      target="patient_id"
      perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
      pagination={<Pagination />}
    >
      <Datagrid
        bulkActionButtons={bulkActionButtons}
      >
        <ReferenceManyField
          label='Diagnoses / HCCs'
          reference="smart_query_item"
          target='smart_query_id'
        >
          <SingleFieldList linkType={false}>
            <FunctionField render={record => {
              let new_record = {text: `[${record.item_id}] ${record.item_description}`};
              return <ChipField size='small' record={new_record} source='text'/>}
            }/>
          </SingleFieldList>
        </ReferenceManyField>
        <ReferenceManyField
          label='Clinical Indicators'
          reference="smart_query_clinical_indicator"
          target='smart_query_id'
        >
          <SingleFieldList linkType={false}>
            <FunctionField render={record => {
              let new_record = {text: `[${record.clinical_indicator}] ${record.value === null ? '' : record.value}`};
              return <ChipField size='small' record={new_record} source='text'/>}
            }/>
          </SingleFieldList>
        </ReferenceManyField>
      </Datagrid>
    </ReferenceManyField>
  )
}


const QueriesPanelDescription = () => (
  <>
    <TextField source="query_text"/>
    <ReferenceManyField
      reference="reconciliation_report_query_clinical_indicator"
      target='reconciliation_report_query_id'
    >
      <SingleFieldList linkType={false}>
        <FunctionField render={record => {
          let new_record = {text: `[${record.clinical_indicator}] ${record.value === null ? '' : record.value}`};
          return <ChipField size='small' record={new_record} source='text'/>}
        }/>
      </SingleFieldList>
    </ReferenceManyField>
  </>
);


const QueriesPanel = () => {
  const record = useRecordContext();

  const isEditable = canEdit(record.state);

  return(
    <Box>
      {isEditable && <Stack direction='row'>
          <Button
              label='Add query'
              component={Link}
              to="/reconciliation_report_query/create"
              state={{ record: { report_id: record.id, query_kind: 'yesno'} ,
                  redirectBack: `/reconciliation_report/${record.id}/show`}}
              icon={<AddIcon />}
          />
          <Button
              label="Add query from previous report"
              component={Link}
              to='/reconciliation_report_query/'
              state={{report_id: record.id, patient_id: record.patient_id}}
          />
      </Stack>}
      <ReferenceManyField
        reference='reconciliation_report_query'
        target="report_id"
        perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
        pagination={<Pagination />}
      >
        <Datagrid
          rowClick='edit'
          bulkActionButtons={isEditable}
        >
          <ReferenceManyField
            label='Diagnoses / HCCs'
            reference="reconciliation_report_query_item"
            target='reconciliation_report_query_id'
          >
            <SingleFieldList linkType={false}>
              <FunctionField render={record => {
                let new_record = {text: `[${record.item_id}] ${record.item_description}`};
                return <ChipField size='small' record={new_record} source='text'/>}
              }/>
            </SingleFieldList>
          </ReferenceManyField>
          <QueriesPanelDescription label='Description' />
          <DateField label='Latest service date' source="latest_service_date"/>
        </Datagrid>
      </ReferenceManyField>
    </Box>
  )
}


const HedisPanel = () => {
  return(
    <Box>
      <ReferenceManyField
        reference='patient_hedis_measure'
        source='patient_id'
        target="patient_id"
        sort={{field: 'compliance,measure_id,compliance_date', order: 'ASC,ASC,ASC'}}
        perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
        pagination={<Pagination />}
      >
        <Datagrid
          rowClick={false}
          bulkActionButtons={false}
        >
          <TextField source='measure_id'/>
          <ReferenceField label='Description' reference='hedis_measure' source='measure_id'>
            <TextField source="description"/>
          </ReferenceField>
          <FunctionField label='Compliance' render={record => record.compliance ? 'Yes' : 'No'}/>
          <DateField source='compliance_date'/>
          <TextField source='non_compliance_reason'/>
          <TextField source='suggested_call_to_action'/>
        </Datagrid>
      </ReferenceManyField>
    </Box>
  )
}

export default ReconciliationReportShow;
