import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  Labeled,
  TextField,
  DateField,
  Toolbar,
  SaveButton,
  Button,
  useRecordContext,
  required,
  SimpleFormIterator,
  useDataProvider,
} from 'react-admin';
import {useState} from 'react';
import {Grid, LinearProgress} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import {useFormContext} from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import {ReferenceManyInput} from "@react-admin/ra-relationships";

import {AssignAppointmentButton} from "./AppointmentButtons";

import UserInput from '../user/UserInput';
import PatientInput from '../patient/PatientInput';
import ProviderPractitionerInput from '../providerpractitioner/ProviderPractitionerInput';
import ReconciliationReportInput from '../reconciliationreport/ReconciliationReportInput';
import { getUserId } from '../auth/sapiensUser';
import DiagnosisInput from "../claim/DiagnosisInput";
import FormIteratorField from "../components/FormIteratorField";
import EnrichedAppointmentDateField from "./EnrichedAppointmentDateField";
import OfficeReferenceField from "../office/OfficeReferenceField";

const appointmentInputSources = [
  'provider_practitioner_id',
  'patient_id',
  'type',
  'status',
  'billed_status',
  'custom_note',
  'reconciliation_report_id',
  'assigned_to'
];

const currentRecordState =  (data) => appointmentInputSources.reduce(
  (obj, inputSource) => Object.assign(obj, { [inputSource]: data[inputSource] }),
  {}
);

const reconciliationReportFilter = (data) => {
  let filters = {
    next_appointment_date: data.date,
    patient_id: (data.patient_id === null) ? 0 : data.patient_id
  };

  if (data.provider_practitioner_id !== null) {
    filters['provider_practitioner_id'] = data.provider_practitioner_id;
  }

  return filters;
};

const AppointmentEditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const AppointmentEditBanner = ({user_id}) => {
  const [userId, setUserId] = useState(null);
  const [bannerValues, setBannerValues] = useState(null);

  const bannerAssignedToMe = {
    'color': '#49de71',
    'msg': 'This appointment is assigned to you',
    'button': null
  };
  const bannerAssignedToOther = {
    'color': '#fa7a73',
    'msg': 'This appointment is assigned to another user',
    'button': null
  };
  const bannerUnassigned = {
    'color': '#e6eb63',
    'msg': 'This appointment is unassigned',
    'button': (
      <AssignAppointmentButton
        userId={userId}
        onSuccess={() => { setBannerValues(bannerAssignedToMe); }}
      />
    )
  };

  if (userId === null) {
    getUserId().then((id) => {
      setUserId(parseInt(id));
      setBannerValues(null);
    });
  }

  if (bannerValues === null) {
    if (user_id === null) {
      setBannerValues(bannerUnassigned);
    }
    else if (user_id === userId) {
      setBannerValues(bannerAssignedToMe);
    }
    else {
      setBannerValues(bannerAssignedToOther);
    }
  }

  return (
    <span style={{
      'width': '100%',
      'backgroundColor': bannerValues?.color,
      'padding': '10px',
      'marginBottom': '10px'
    }}>
      <span>{bannerValues?.msg}</span>
      <span style={{'marginLeft': '100px'}}>{bannerValues?.button}</span>
    </span>
  );
};

const PatientState = (data) => {
  const dataProvider = useDataProvider();
  const [gkId, setGkId] = useState(null);

  const name_parts = data.patient_name.split(',');

  dataProvider.getOne('office', {id: data.office_id}).then((r) => setGkId(r.data.gatekeeper_id));

  let insurance = null;
  ['careplus', 'humana', 'simply', 'devoted', 'cigna', 'aetna'].forEach((value) => {
    if (data.insurance.toLowerCase().includes(value)) {
      insurance = value;
    }
  });

  return {
    record: {
      first_name: name_parts.length > 1 ? name_parts[1].trim() : null,
      last_name: name_parts.length > 0 ? name_parts[0].trim() : null,
      insurance_provider_id: insurance,
      id_type: insurance,
      id_value: data.insurance_id,
      date_of_birth: data.patient_dob,
      gatekeeper_id: gkId,
      redirect_values: {
        page: 'edit',
        resource: 'appointment',
        resource_id: data.id,
        state: {
          record: { ...currentRecordState(data), ...{ patient_id: '$replace-id' } }
        }
      }
    }
  };
};

const AppointmentPatient = ({values}) => (
  <>
    <Grid container direction="row" spacing={5}>
      <Grid item>
        <Labeled label="Insurance">
          <TextField source="insurance"/>
        </Labeled>
      </Grid>
      <Grid item>
        <Labeled label="Insurance ID">
          <TextField source="insurance_id"/>
        </Labeled>
        </Grid>
    </Grid>
    <Grid container direction="row" spacing={5}>
      <Grid item>
        <Labeled label="Name">
          <TextField source="patient_name"/>
        </Labeled>
      </Grid>
      <Grid item>
        <Labeled label="Date of Birth">
          <DateField source="patient_dob"/>
        </Labeled>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <PatientInput label="Patient" source="patient_id" name="patient_id"/>
      </Grid>
      <Grid item>
        <Button
          label="Create Patient"
          component={Link}
          to="/patient/create"
          state={PatientState(values)}
          children={<AddIcon />}
          sx={{padding: "20px 0"}}
        />
      </Grid>
    </Grid>
  </>
);

const DiagnosisAcceptedYearField = ({conditionYear, model, referenceRecord, ...rest}) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [childContent, setChildContent] = useState(null);
  const labelText = `Accepted on ${conditionYear}/${model}`

  if (referenceRecord.diagnosis_id === null) return null;

  if (childContent === null) {
    if (record.patient_id === null) {
      setChildContent('No patient');
    } else {
      dataProvider.getList(
        'diagnosis_condition_related',
        {
          filter: {
            diagnosis_id: referenceRecord.diagnosis_id,
            model: model
          }
        }
      ).then((response) => {
        if (response.total === 0) {
          setChildContent('No HCC');
        } else {
          let diagnoses = response.data.map((item) => item.related_diagnosis_id)
          dataProvider.getList(
            'patient_diagnosis_year',
            {
              filter: {
                patient_id: record.patient_id,
                'diag_code@_in': diagnoses,
                condition_year_id: conditionYear,
                mao_flag_int: 40
              }
            }
          ).then((response) => {
            if (response) {
              setChildContent(response.total > 0 ? 'Yes' : 'No');
            }
          });
        }
      }).catch((error) => {
        console.log('Error while getting accepted status', error);
      });
    }
  }
  return childContent === null
    ? <LinearProgress sx={{ width: '25px', marginTop:'12px' }}/>
    : <Labeled label={labelText} ><TextField source="content" record={{content: `${childContent}`}}/></Labeled>;
}

const DiagnosisHccField = ({model, referenceRecord, ...rest}) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [childContent, setChildContent] = useState(null);
  const labelText = `HCC ${model}`

  if (referenceRecord.diagnosis_id === null) return null;

  if (childContent === null) {
    if (record.patient_id === null) {
      setChildContent('No patient');
    } else {
      dataProvider.getList(
        'diagnosis_condition',
        {
          filter: {
            id: referenceRecord.diagnosis_id,
            model: model
          }
        }
      ).then((response) => {
        if (response.total === 0) {
          setChildContent('No HCC');
        } else {
          setChildContent(response.data.map(dc => dc.condition_id).join(', '));
        }
      }).catch((error) => {
        console.log('Error while getting HCC', error);
      });
    }
  }
  return childContent === null
    ? <LinearProgress sx={{ width: '25px', marginTop:'12px' }}/>
    : <Labeled label={labelText} ><TextField source="content" record={{content: `${childContent}`}}/></Labeled>;
}

const AppointmentEditInputs = () => {
  const record = useRecordContext();
  const { setValue, getValues } = useFormContext();

  let { state } = useLocation();

  appointmentInputSources.map((inputSource) => {
    if (state?.record?.[inputSource] !== undefined) {
      setValue(
        inputSource,
        state.record[inputSource],
        { shouldDirty: (record[inputSource] !== state.record[inputSource]) }
      );
    }
  });

  return (
    <>
      <AppointmentEditBanner user_id={record.assigned_to} />
      <Labeled label="Appointment date">
        <EnrichedAppointmentDateField source='date'/>
      </Labeled>
      <Labeled label="Appointment created in system date">
        <DateField source="created_at" showTime={true}/>
      </Labeled>
      <Labeled label="Office">
        <OfficeReferenceField />
      </Labeled>
      <Labeled label="Provider name">
        <TextField source="provider_name"/>
      </Labeled>
      <ProviderPractitionerInput source="provider_practitioner_id" label="Provider" />
      <AppointmentPatient values={getValues()} />
      <Labeled label="Loaded Type">
        <TextField source="appointment_type"/>
      </Labeled>
      <ReferenceInput source="type" reference="appointment_type" sort={{ field: "description", order: "ASC" }}>
        <AutocompleteInput
          optionText="description"
          validate={required()}
          filterToQuery={(searchText) => ({ "description@_ilike": searchText })}
        />
      </ReferenceInput>
      <Labeled label="Loaded Status">
        <TextField source="appointment_status"/>
      </Labeled>
      <ReferenceInput source="status" reference="appointment_status" sort={{ field: "description", order: "ASC" }}>
        <AutocompleteInput
          optionText="description"
          validate={required()}
          filterToQuery={(searchText) => ({ "description@_ilike": searchText })}
        />
      </ReferenceInput>
      <BooleanInput source="warning" />
      <Labeled label="Loaded note">
        <TextField source="notes" />
      </Labeled>
      <BooleanInput source="archived" />
      <ReferenceInput
        source="billed_status"
        reference="appointment_billed_status"
        sort={{ field: "description", order: "ASC" }}
      >
        <SelectInput optionText="description" validate={required()} />
      </ReferenceInput>
      <TextInput fullWidth multiline source="custom_note"/>
      <ReconciliationReportInput filter={reconciliationReportFilter(getValues())} />
      <UserInput label="Assigned to" source="assigned_to" />
      <ReferenceManyInput reference='appointment_billing_omission' target='appointment_id' sort={{field: 'diagnosis_id', order: 'ASC'}}>
        <SimpleFormIterator inline>
          <DiagnosisInput source="diagnosis_id"  reference='diagnosis'/>
          <FormIteratorField>
            <DiagnosisHccField model='v24'/>
          </FormIteratorField>
          <FormIteratorField>
            <DiagnosisHccField model='v28'/>
          </FormIteratorField>
          <FormIteratorField>
            <DiagnosisAcceptedYearField conditionYear={280} model='v24'/>
          </FormIteratorField>
          <FormIteratorField>
            <DiagnosisAcceptedYearField conditionYear={285} model='v24'/>
          </FormIteratorField>
          <FormIteratorField>
            <DiagnosisAcceptedYearField conditionYear={280} model='v28'/>
          </FormIteratorField>
          <FormIteratorField>
            <DiagnosisAcceptedYearField conditionYear={285} model='v28'/>
          </FormIteratorField>
        </SimpleFormIterator>
      </ReferenceManyInput>
    </>
  );
};

const AppointmentEdit = () => (
  <Edit mutationMode='pessimistic'>
    <SimpleForm toolbar={<AppointmentEditToolbar />}>
      <AppointmentEditInputs />
    </SimpleForm>
  </Edit>
);

export default AppointmentEdit;
