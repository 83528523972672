import React from 'react';
import {Page, Text, View, Document, StyleSheet, Svg, Rect, Path, Image} from '@react-pdf/renderer';
import {cyToYear} from "../utils";
import inqoraLogo from "../assets/inqora_logo.png";
import {VITE_MSO_LOGO_URL} from "../config";

const tableCol = (width) => ({width: `${width.toString()}%`, marginRight: "5px"});


// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
//    backgroundColor: 'rgb(228, 228, 228)'
  },
  section: {
    margin: 6,
    padding: 6,
//    flexGrow: 1
  },
  row: {
    padding: 1,
    flexGrow: 1,
    flexDirection: 'row',
  },
  column: {
    margin: 3,
    padding: 0,
//    flexGrow: 1,
    flexDirection: 'column',
  },
  h1: {
    fontSize: 14,
    fontFamily: "Helvetica-Bold"
  },
  h2: {
    fontSize: 10,
    fontFamily: "Helvetica-Bold"
  },
  h3: {
    fontSize: 8,
    fontFamily: "Helvetica-Bold"
  },
  textMedium: {
    fontSize: 8,
    fontFamily: "Helvetica"
  },
  th: {
    backgroundColor: "rgba(100,100,100,0.5)",
  },
  tr: {
    borderBottomWidth: 0.5,
    borderBottomStyle: 'solid',
    borderBottomColor: '#999999',
  },
  boxBorder: {
    borderStyle: 'solid',
    borderWidth: 1,
    width: '20px'
  },
  textSmall: {
    fontSize: 6,
    fontFamily: "Helvetica",
  },
  overflowEllipsis: {
    textOverflow: 'ellipsis',
    maxLines: 1
  }
});

const Checkbox = () => (
  <Svg height="8" width="8" style={{marginRight: '5px'}}>
    <Rect x="0" y="0" width="8" height="8" fill="white" stroke="black" strokeWidth="1" />
  </Svg>
);

const Checkmark = () => (
  <Svg height="10" width="10" viewBox="0 0 448 512" >
    <Path
      fill="black"
      d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
  </Svg>
);

// Create Document Page Component
const DiagnosisReconciliationPage = (props) =>{
  let report = props.report;
  report.patient.accepted_conditions_2 = report.patient.accepted_conditions_2.filter((accCondition) => accCondition.model === report.model);
  report.past_diagnoses_queries = report.queries.filter((query) => query.query_kind === 'yesno' );
  report.clinical_indicator_queries = report.queries.filter((query) => query.query_kind === 'diagnosis' || query.query_kind === 'smart');
  report.cdi_queries = report.queries.filter((query) => query.query_kind === 'cdi');
  const hcc_score_previous = report.patient.risk_score_history.filter((score) => score.condition_year_id === 275)[0]?.hcc_score;
  const hcc_score_half = report.patient.risk_score_history.filter((score) => score.condition_year_id === 280)[0]?.hcc_score;
  const hcc_score_current = report.patient.risk_score_history.filter((score) => score.condition_year_id === 285)[0]?.hcc_score;

  return (
    <Page size="A4" style={styles.page}>
      <View style={{flexDirection: 'row', width: '100%', marginTop: '5px'}} fixed={true}>
        <Image src={inqoraLogo} style={{height: '25px', marginHorizontal: '10px'}}/>
        <div style={{flexGrow: 1}}>&nbsp;</div>
        {VITE_MSO_LOGO_URL === undefined ? null : <Image src={VITE_MSO_LOGO_URL} style={{height: '25px', marginHorizontal: '10px', alignSelf: 'end'}}/>}
      </View>
      {/* HEADER */}
      <View style={styles.section}>
        <Text style={styles.h1}>Patient Inquiry</Text>
        <View style={[styles.row, {minHeight: '15px'}]}>
          <View style={[tableCol(33)]}>
            <View style={{flexDirection: 'row'}}>
              <Text style={[styles.h3]}>Name: </Text><Text style={styles.textMedium}>{props.dev ? 'XXXXX YYY' : report.patient.name}</Text>
            </View>
            <View style={{flexDirection: 'row'}}>
              <Text style={[styles.h3]}>ID: </Text><Text style={styles.textMedium}>{props.dev ? 'HXXXXXXXXXX' : report.patient.current_eligibility?.id_value}</Text>
            </View>
            <View style={{flexDirection: 'row'}}>
              <Text style={[styles.h3]}>Date of birth: </Text><Text style={styles.textMedium}>{props.dev ? 'YYYY-MM-DD' : report.patient.date_of_birth}</Text>
            </View>
          </View>
          <View style={[tableCol(33)]}>
            <View style={{flexDirection: 'row'}}>
              <Text style={[styles.h3]}>Main PCP: </Text><Text style={styles.textMedium}>{report.gatekeeper?.name}</Text>
            </View>
            <View style={{flexDirection: 'row'}}>
              <Text style={[styles.h3]}>Appointment Doctor:  </Text><Text style={styles.textMedium}>{report.provider_practitioner?.name}</Text>
            </View>
            <View style={{flexDirection: 'row'}}>
              <Text style={[styles.h3]}>Date of appointment: </Text><Text style={styles.textMedium}>{report.next_appointment_date}</Text>
            </View>
          </View>
          <View style={[tableCol(33)]}>
            <View style={{flexDirection: 'row'}}>
              <Text style={[styles.h3]}>HCC score 2024 (275): </Text><Text style={styles.textMedium}>{hcc_score_previous ? hcc_score_previous : 'N/A'}</Text>
            </View>
            <View style={{flexDirection: 'row'}}>
              <Text style={[styles.h3]}>HCC score 2024-2025 (280): </Text><Text style={styles.textMedium}>{hcc_score_half ? hcc_score_half : 'N/A'}</Text>
            </View>
            <View style={{flexDirection: 'row'}}>
              <Text style={[styles.h3]}>HCC score 2025 (285):  </Text><Text style={styles.textMedium}>{hcc_score_current ? hcc_score_current : 'N/A'}</Text>
            </View>
          </View>
        </View>
      </View>
      {/* END HEADER */}

      {/* CURRENTLY ACCEPTED DIAGNOSIS */}
      {report.patient.accepted_conditions_2.length === 0
        ? null
        :
      <View style={styles.section} wrap={false}>
        <Text style={[styles.h2, {marginBottom: '5px'}]}>Currently Accepted Diagnosis</Text>
        {/*Header Row */}
        <View style={[styles.th, styles.row, {minHeight: '15px'}]}>
          <View style={[tableCol(60)]}>
            <Text style={styles.h3}>Condition</Text>
          </View>
          <View style={[tableCol(5)]}>
            <Text style={styles.h3}>{cyToYear(265)}</Text>
          </View>
          <View style={[tableCol(5)]}>
            <Text style={styles.h3}>{cyToYear(275)}</Text>
          </View>
          <View style={[tableCol(5)]}>
            <Text style={styles.h3}>{cyToYear(285)}</Text>
          </View>
          <View style={[tableCol(30)]}>
            <Text style={styles.h3}>Provider</Text>
          </View>
        </View>

        { report.patient.accepted_conditions_2.map(function(accCondition, idx) {
            return (
              <View key={`a1-${idx}`} style={[styles.tr, styles.row]}>
                <View style={tableCol(60)}>
                  <Text style={styles.textMedium}>{accCondition['description']} [{accCondition['condition_id']}]</Text>
                </View>
                <View style={tableCol(5)}>
                  {accCondition['historic_years'].includes(265) ? <Checkmark/> : null}
                </View>
                <View style={tableCol(5)}>
                  {accCondition['historic_years'].includes(275) ? <Checkmark/> : null}
                </View>
                <View style={tableCol(5)}>
                  {accCondition['historic_years'].includes(285) ? <Checkmark/> : null}
                </View>
                <View style={tableCol(30)}>
                  {/* latest_provider sometimes is null, we have to handle that */}
                  <Text style={styles.textMedium}>{accCondition.latest_provider?.name}</Text>
                </View>
              </View>
            );
          })
        }
      </View>
      }
      {/* END CURRENTLY ACCEPTED DIAGNOSIS */}

      {/*PAST DIAGNOSES*/}
      {  report.past_diagnoses_queries.length === 0
        ? null
        :
      <View style={styles.section} wrap={false}>
        <Text style={[styles.h2, {marginBottom: '5px'}]}>Past Diagnoses</Text>
        <Text style={[styles.textMedium, {marginBottom: '5px'}]}>
          After review of the medical record, the following diagnoses were last documented on the dates shown below.
          Are these diagnoses still clinically relevant to the patients current health status?
        </Text>

        {/*Header Row */}
        <View style={[styles.th, styles.row]}>
          <View style={[tableCol(20)]}>
            <Text style={styles.h3}>Provider</Text>
          </View>
          <View style={[tableCol(10)]}>
            <Text style={styles.h3}>Date</Text>
          </View>
          <View style={[tableCol(70)]}>
            <Text style={styles.h3}>Diagnoses</Text>
          </View>
          <View style={[tableCol(10)]}>
            <Text style={styles.h3}>Y / N</Text>
          </View>
        </View>

        { report.past_diagnoses_queries.map(function(query, idx) {
            return (
              <View key={`x2-${idx}`} style={styles.tr}>
                <View key={`a2-${idx}`} style={styles.row}>
                  <View style={tableCol(20)}>
                    <Text style={styles.textMedium}>
                      {query.latest_provider ? query.latest_provider.name : 'N/A'}
                    </Text>
                  </View>
                  <View style={tableCol(10)}>
                    <Text style={styles.textMedium}>
                      {query.latest_service_date ? query.latest_service_date : 'N/A'}
                    </Text>
                  </View>
                  <View style={tableCol(70)}>
                    <Text style={styles.textMedium}>{query.diagnoses[0].diagnosis_description} [{query.diagnoses[0].diagnosis_id}]</Text>
                  </View>
                  <View style={[tableCol(10)]}>
                    <View style={[styles.row, styles.textMedium]}>
                      <Checkbox/><Checkbox/>
                    </View>
                  </View>
                </View>
              </View>
            );
          })
        }
      </View>
      }
      {/* END PAST DIAGNOSES */}

      {/* CLINICAL INDICATORS */}
      {  report.clinical_indicator_queries.length === 0
        ? null
        :
      <View style={styles.section} wrap={false}>
        <Text style={[styles.h2, {marginBottom: '5px'}]}>Clinical Indicators</Text>
        <Text style={[styles.textMedium, {marginBottom: '5px'}]}>
          The following clinical indicators have been identified in the patient's medical record and/or clinical history.
          Based on your clinical judgement, if you agree the patient currently has these condition(s),
          please document in the chart. (A = Agree, D = Disagree, NR = Not clinically relevant)
        </Text>

        {/*Header Row */}
        <View style={[styles.th, styles.row]}>
          <View style={[tableCol(50)]}>
            <Text style={styles.h3}>Clinical Indicators</Text>
          </View>
          <View style={[tableCol(40)]}>
            <Text style={styles.h3}>Diagnoses / HCCs</Text>
          </View>
          <View style={[tableCol(10)]}>
            <Text style={styles.h3}>A / D / NR</Text>
          </View>
        </View>

        { report.clinical_indicator_queries.map(function(query, idx) {
            return (
              <View key={`x2-${idx}`} style={styles.tr}>
                <View key={`a2-${idx}`} style={styles.row}>
                  <View style={[tableCol(50)]}>
                    { query.clinical_indicators.map((ci, idx) => {
                      let main_text = (query.query_kind === 'smart') ?
                        (ci.reference_op ? ci.reference_op : '') :
                        (ci.clinical_indicator ? ci.clinical_indicator : '');
                      if (ci.value){
                        main_text = main_text + ': ' + ci.value;
                      }
                      return (
                        <View key={`x2-${idx}`}>
                          <View key={`a2-${idx}`} style={styles.row}>
                            <Text style={[styles.textMedium,tableCol(40)]}>
                              {
                                query.query_kind === 'smart' ?
                                  (ci.clinical_indicator ? ci.clinical_indicator : 'N/A') :
                                  (ci.provider ? ci.provider.name : 'N/A')
                              }
                            </Text>
                            <Text style={[styles.textMedium,tableCol(20)]}>
                              {
                                query.query_kind === 'smart' ?
                                  (ci.reference_date ? ci.reference_date : 'N/A') :
                                  (ci.date_reported ? ci.date_reported : 'N/A')
                              }
                            </Text>
                            <Text style={[styles.textMedium,tableCol(40)]}>
                              {main_text}
                            </Text>
                          </View>
                        </View>
                        );
                      })
                    }
                  </View>
                  <View style={[tableCol(40)]}>
                    { query.items.map((queryItem, idx) => {
                      return (<View style={[styles.row]}>
                                <Text key={queryItem.item_id} style={styles.textMedium}>{queryItem.item_description} [{queryItem.item_id}]</Text>
                              </View>);
                    })}
                  </View>
                  <View style={[tableCol(10)]}>
                    { query.items.map((queryItem, idx) => {
                      return (
                        <View style={[styles.row, styles.textMedium]}>
                          <Checkbox/><Checkbox/><Checkbox/>
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
            );
          })
        }
      </View>
      }
      {/* END CLINICAL INDICATORS*/}

      {/* CDI */}
      {  report.cdi_queries.length === 0
        ? null
        :
      <View style={styles.section} wrap={false}>
        <Text style={[styles.h2, {marginBottom: '5px'}]}>Clinical Documentation Improvement</Text>
        <Text style={[styles.textMedium, {marginBottom: '5px'}]}>
          After review of the medical record, the following condition(s) require further clarification:
        </Text>

        {/*Header Row */}
        <View style={[styles.th, styles.row]}>
          <View style={[tableCol(15)]}>
            <Text style={styles.h3}>Provider</Text>
          </View>
          <View style={[tableCol(9)]}>
            <Text style={styles.h3}>Date</Text>
          </View>
          <Text style={[tableCol(68), styles.h3]}>
            Description
          </Text>
          <Text style={[tableCol(8), styles.h3]}>
            Clarified in note
          </Text>
        </View>

        { report.cdi_queries.map(function(query, idx) {
            return (
              <View key={`x2-${idx}`} style={styles.tr}>
                <View key={`a2-${idx}`} style={styles.row}>
                  <Text style={[styles.textMedium,tableCol(15)]}>
                      {query.latest_provider ? query.latest_provider.name : 'N/A'}
                  </Text>
                  <View style={[tableCol(9)]}>
                    <Text style={styles.textMedium}>
                      {query.latest_service_date ? query.latest_service_date : 'N/A'}
                    </Text>
                  </View>
                  <View style={[tableCol(68)]}>
                    <Text style={styles.textMedium}>
                      {query.query_text ? query.query_text : 'N/A'}
                    </Text>
                  </View>
                  <View style={[tableCol(8)]}>
                    <View style={[styles.row, styles.textMedium, {marginLeft: '15px'}]}>
                      <Checkbox/>
                    </View>
                  </View>
                </View>
              </View>
            );
          })
        }
      </View>
      }
      {/* END CDI*/}
      {/* HEDIS MEASURES */}
      {  !report.include_hedis_measures || report.patient.hedis_measures.length === 0
        ? null
        :
        <View style={styles.section} wrap={false}>
          <Text style={[styles.h2, {marginBottom: '5px'}]}>Hedis Measures</Text>

          {/*Header Row */}
          <View style={[styles.th, styles.row]}>
            <Text style={[tableCol(16), styles.h3]}>
              Measure
            </Text>
            <Text style={[tableCol(70), styles.h3]}>
              Description
            </Text>
            <Text style={[tableCol(14), styles.h3]}>
              Compliance
            </Text>
            <Text style={[tableCol(11), styles.h3]}>
              Compliance date
            </Text>
          </View>

          { report.patient.hedis_measures.map(function(element, idx) {
            return (
              <View key={`f2-${idx}`} style={styles.tr}>
                <View key={`y2-${idx}`} style={styles.row}>
                  <Text style={[styles.textMedium,tableCol(16)]}>
                    {element.measure_id}
                  </Text>
                  <Text style={[styles.textMedium,tableCol(70)]}>
                    {element.measure.description}
                  </Text>
                  <Text style={[styles.textMedium,tableCol(14)]}>
                    {element.compliance ? 'Yes' : 'No'}
                  </Text>
                  <Text style={[tableCol(11), styles.textMedium]}>
                    {element.compliance_date ? element.compliance_date : ''}
                  </Text>
                </View>
                {element.non_compliance_reason &&
                  <View key={`j2-${idx}`} style={styles.row}>
                    <Text style={[styles.textMedium, {marginLeft: '5px'}]}>
                      {element.non_compliance_reason}
                    </Text>
                  </View>
                }
                {element.suggested_call_to_action &&
                  <View key={`h2-${idx}`} style={styles.row}>
                    <Text style={[styles.textMedium, {marginLeft: '5px'}]}>
                      {element.suggested_call_to_action}
                    </Text>
                  </View>
                }
              </View>
            );
          })
          }
        </View>
      }
      {/* END CDI*/}
    </Page>
  )
};

const addReportPdf = (reports, dev) => (
  (reports.length > 0) ? reports.map(
     (report) => <DiagnosisReconciliationPage report={report} dev={dev}/>
  ) : null
);

// Create Document Component
const DiagnosisReconciliationPDF = (props) => (
  <Document>
    {addReportPdf(props.reports, props.dev)}
  </Document>
);

export default DiagnosisReconciliationPDF;
