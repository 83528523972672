import {
  Datagrid, DateField, Pagination, ReferenceField, TextField, ReferenceManyField,
} from "react-admin";
import {FormControlLabel, Stack, Switch} from "@mui/material"
import ServiceOriginField from "../claim/ServiceOriginField";
import CMSStatusField from "../claim/CMSStatusField";
import ProviderReferenceField from "../provider/ProviderReferenceField";
import ClaimReferenceField from "../claim/ClaimReferenceField";
import {useState} from "react";
import {CondyearDiagnosisClaimStatusIcon} from "../claim/CondyearDiagnosisClaimStatusIcon";
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";


const PatientDiagnosisPanel = () => {
  return (<ReferenceManyField reference="patient_diagnosis_services"
                              target="patient_diagnosis_id"
                              sort={{field: 'srv_frm_dt,claim_nbr', order: 'DESC,ASC'}}
                              perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
                              pagination={<Pagination/>}>
      <Datagrid bulkActionButtons={false}>
        <DateField source="srv_frm_dt" label="Date"/>
        <ServiceOriginField label='Origin'/>
        <ProviderReferenceField />
        <CMSStatusField label="CMS Status"/>
        <TextField source="service_desc" label="Service"/>
        <ClaimReferenceField label="Claim #"/>
      </Datagrid>
    </ReferenceManyField>);
};


export const PatientShowDiagnosisTab = () => {
  const [onlyHCC, setOnlyHCC] = useState(false);
  const [useV28, setUseV28] = useState(true);

  const model = useV28 ? 'v28' : 'v24';
  let filter = {};
  if (onlyHCC) {
    filter = {
      'diagnosis_condition#condition_id@_is_null': false, 'diagnosis_condition#model@_eq': model
    }
  }

  return (
    <>
      <Stack direction="row" spacing={2}>
        <FormControlLabel
          control={<Switch checked={onlyHCC} onChange={(event, value) => setOnlyHCC(value)}/>}
          label="Show HCC only"
        />
        <FormControlLabel
          label='Use model v28'
          control={<Switch checked={useV28} onChange={(event, value) => setUseV28(value)}/>}
        />
      </Stack>
      <ReferenceManyField
        label="Diagnoses"
        reference="patient_diagnosis_years"
        target="patient_id"
        filter={filter}
        sort={{field: 'frm_dt_src_max', order: 'DESC'}}
        perPage={50}
        pagination={<Pagination/>}
      >
        <Datagrid
          expand={<PatientDiagnosisPanel/>}
          bulkActionButtons={false}
        >
          <TextField source="diag_code" label="Diagnosis Code"/>
          <ReferenceField
            label="HCC"
            source="diag_code"
            reference="diagnosis_conditions_agg"
          >
            <TextField source={`conditions_${model}`}/>
          </ReferenceField>
          <CondyearDiagnosisClaimStatusIcon label={<div>265<br/>(2023)</div>} conditionyear="265" source="history_status"/>
          <CondyearDiagnosisClaimStatusIcon label={<div>275<br/>(2024)</div>} conditionyear="275" source="prior_status"/>
          <CondyearDiagnosisClaimStatusIcon label={<div>280<br/>(2024-25)</div>} conditionyear="280" source="future_status"/>
          <CondyearDiagnosisClaimStatusIcon label={<div>285<br/>(2025)</div>} conditionyear="285" source="current_status"/>
          <ReferenceField source="diag_code" reference="diagnosis" label="Description">
            <TextField source="description"/>
          </ReferenceField>
          <DateField source="frm_dt_src_max" label="Most Recent Service"/>
        </Datagrid>
      </ReferenceManyField>
    </>
  );
}

export default PatientShowDiagnosisTab;
