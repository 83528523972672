import {
  Datagrid,
  DateField,
  DateInput,
  ExportButton,
  FilterButton,
  List,
  NullableBooleanInput,
  Pagination,
  ReferenceField,
  TextField,
  TextInput, TopToolbar
} from 'react-admin';
import BillingClaimPatientField from "./BillingClaimPatientField";
import GatekeeperReferenceField from "../gatekeeper/GatekeeperReferenceField";
import PatientInput from "../patient/PatientInput";
import GatekeeperInput from "../providerpractitioner/GatekeeperInput";
import BilledStatusInput from "./BilledStatusInput";
import ConditionYearInput from "../claim/ConditionYearInput";
import {DiagnosisArrayInput} from "../claim/DiagnosisInput";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";
import downloadCSV from "ra-core/src/export/downloadCSV";
import jsonExport from 'jsonexport/dist';
import {parseDateToEndFfDayTimeStamp} from "../utils";

const ListActions = () => (
  <TopToolbar>
    <FilterButton/>
    <ExportButton maxResults={-1}/>
  </TopToolbar>
);

const filters = [
  <PatientInput label="Member" source="patient_id" name='patient_id' alwaysOn/>,
  <TextInput label="Visit Id" source="visit_id" resettable={true} alwaysOn />,
  <NullableBooleanInput label="Characterized" source="characterized" />,
  <GatekeeperInput label="PCP" source="gatekeeper_id" alwaysOn/>,
  <BilledStatusInput source="billed_status@_in" alwaysOn/>,
  <DateInput label="Service date from" source="service_date@_gte" />,
  <DateInput label="Service date to" source="service_date@_lte" />,
  <DateInput label="Billed date from" source="billed_date@_gte" />,
  <DateInput label="Billed date to" source="billed_date@_lte" />,
  <ConditionYearInput label="Condition year" source="condition_years#condition_year_id@_eq"/>,
  <DiagnosisArrayInput label="Includes one or more Diagnosis" source='diagnoses#diag_code@_in'/>,
  <TextInput label="Origin created by" source="origin_created_by" resettable={true}/>,
  <DateInput label="Origin created date from" source="origin_created_at@_gte" />,
  <DateInput label="Origin created date to" source="origin_created_at@_lte"
             parse={parseDateToEndFfDayTimeStamp}/>,
  <TextInput label="Origin updated by" source="origin_updated_by" resettable={true}/>,
  <DateInput label="Origin updated date from" source="origin_updated_at@_gte" />,
  <DateInput label="Origin updated date to" source="origin_updated_at@_lte"
             parse={parseDateToEndFfDayTimeStamp}/>,
];

const exporter = async (billingClaims, fetchRelatedRecords, __, resource) => {
  const patients = await fetchRelatedRecords(billingClaims, 'patient_id', 'patient')
  let enrichedBillingClaims = billingClaims.map(
    billingClaim => {
      if (billingClaim.patient_id === undefined || billingClaim.patient_id === null) {
        return billingClaim;
      }
      const patient = patients[billingClaim.patient_id];
      return {
        ...billingClaim,
        patient_name: patient.name,
        patient_dob: patient.date_of_birth,
        patient_insurance_id: patient.current_eligibility?.id_value
      };
    }
  );
  const gatekeepers = await fetchRelatedRecords(billingClaims, 'gatekeeper_id', 'gatekeeper')
  enrichedBillingClaims = enrichedBillingClaims.map(
    billingClaim => {
      if (billingClaim.gatekeeper_id === undefined || billingClaim.gatekeeper_id === null) {
        return billingClaim;
      }
      return {
        ...billingClaim,
        gatekeeper_name: gatekeepers[billingClaim.gatekeeper_id].name,
      };
    }
  );
  return jsonExport(
    enrichedBillingClaims,
    { headers: [
      'id',
      'visit_id',
      'gatekeeper_id',
      'gatekeeper_name',
      'patient_id',
      'patient_name',
      'patient_insurance_id',
      'patient_dob',
      'service_date',
      'billed_date',
      'billed_status',
      'origin_created_by',
      'origin_created_at',
      'origin_updated_by',
      'origin_updated_at'
    ] },// order fields in the export
    (err, csv) => downloadCSV(csv, resource));
}

const BillingClaimList = () => (
  <List
    actions={<ListActions/>}
    pagination={<Pagination/>}
    perPage={VITE_DEFAULT_LIST_PER_PAGE}
    exporter={exporter}
    filters={filters}
    sort={{field: 'service_date', order: 'DESC'}} >

    <Datagrid rowClick="show">
      <TextField source="id" label="Inqora ID" />
      <ReferenceField source="visit_id" reference="visit" link='show'/>
      <GatekeeperReferenceField />
      <BillingClaimPatientField label='Member' />
      <DateField source="service_date" />
      <DateField source="billed_date" />
      <TextField source="billed_status"/>
      <TextField source="origin_created_by"/>
      <DateField source="origin_created_at" />
      <TextField source="origin_updated_by"/>
      <DateField source="origin_updated_at" />
    </Datagrid>
  </List>
)

export default BillingClaimList;
