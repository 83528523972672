import {
  Datagrid,
  DateField, DateInput,
  List,
  Pagination,
} from 'react-admin';
import PatientNameReferenceField from "../patient/PatientNameReferenceField";
import GatekeeperReferenceField from "../gatekeeper/GatekeeperReferenceField";
import PatientInput from "../patient/PatientInput";
import GatekeeperInput from "../providerpractitioner/GatekeeperInput";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";


const visitFilters = [
  <PatientInput label="Member" source="patient_id" name='patient_id' alwaysOn/>,
  <DateInput label="From date" source="service_date@_gte" alwaysOn/>,
  <DateInput label="To date" source="service_date@_lte" alwaysOn/>,
  <GatekeeperInput label="PCP" source="gatekeeper_id" alwaysOn/>,
];


const VisitList = () => (
  <List
    pagination={<Pagination/>}
    perPage={VITE_DEFAULT_LIST_PER_PAGE}
    filter={{
      'gatekeeper_id@_is_null': false
    }}
    filters={visitFilters}
    sort={{field: 'service_date,id', order: 'DESC,DESC'}} >
    <Datagrid rowClick="show">
      <PatientNameReferenceField label='Member' />
      <GatekeeperReferenceField />
      <DateField source="service_date" />
    </Datagrid>
  </List>
);

export default VisitList;
