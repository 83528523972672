import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceManyField, Datagrid
} from "react-admin";


export const BillingClaimShowOrigin = () => (
  <Show>
    <SimpleShowLayout>
      <ReferenceManyField
        label="Origin"
        reference="billing_claim_origin_row"
        source="id"
        target="billing_claim_id"
      >
        <Datagrid>
          <TextField source="id" />
          <TextField source="primary_payer" />
          <TextField label= 'Member insurance id' source="primary_insurance_id" />
          <TextField source="name" />
          <TextField source="dob" />
          <TextField source="charges_cent" />
          <TextField source="place" />
          <TextField source="claim_id" />
          <TextField source="service_date" />
          <TextField source="diag1" />
          <TextField source="diag2" />
          <TextField source="diag3" />
          <TextField source="diag4" />
          <TextField source="diag5" />
          <TextField source="diag6" />
          <TextField source="diag7" />
          <TextField source="diag8" />
          <TextField source="diag9" />
          <TextField source="diag10" />
          <TextField source="diag11" />
          <TextField source="diag12" />
          <TextField source="procedure" />
          <TextField source="rendering_provider" />
          <TextField source="billing_npi" />
          <TextField source="m1" />
          <TextField source="first_data_file_id" />
          <TextField source="last_data_file_id" />
          <TextField source="account_num" />
          <TextField source="claim_status" />
          <TextField source="bill_date" />
          <TextField source="created_by" />
          <TextField source="created_date" />
          <TextField source="updated_by" />
          <TextField source="updated_date" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export default BillingClaimShowOrigin;
