import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ReferenceManyField,
  Datagrid, ReferenceField, SingleFieldList, ReferenceArrayField, ChipField,
  Pagination
} from "react-admin";
import PatientNameReferenceField from '../patient/PatientNameReferenceField';
import CMSStatusField from "../claim/CMSStatusField";
import ServiceCategoryField from "../claim/ServiceCategoryField";
import GatekeeperReferenceField from "../gatekeeper/GatekeeperReferenceField";
import {SapiensFieldsRow} from "../components/SapiensFieldsRow";
import CollapsibleSection from "../components/CollapsibleSection";
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";

export const VisitShow = () => (
  <Show>
    <SimpleShowLayout>
      <SapiensFieldsRow>
        <TextField source="id" />
        <PatientNameReferenceField label='Member' />
        <GatekeeperReferenceField />
        <DateField source="service_date" />
      </SapiensFieldsRow>
      <CollapsibleSection title="Billing Claims" initialOpen={false}>
        <ReferenceManyField
          label='Billing Claims'
          reference='billing_claim'
          target='visit_id'
          perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
          pagination={<Pagination />}
        >
          <Datagrid bulkActionButtons={false} rowClick="show">
            <TextField label='Billing system ID' source="claim_nbr"/>
            <DateField source='service_date'/>
            <DateField source='billed_date'/>
            <TextField source='billed_status'/>
          </Datagrid>
        </ReferenceManyField>
      </CollapsibleSection>
      <CollapsibleSection title="Payer Claims" initialOpen={false}>
      <ReferenceManyField
        label='Payer Claims'
        reference='claim'
        target='visit_id'
        filter={{service_category: 'md'}}
        perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
        pagination={<Pagination />}
      >
        <Datagrid bulkActionButtons={false} rowClick="show">
          <TextField label='Claim ID' source="claim_nbr"/>
          <CMSStatusField label="CMS Status"/>
          <ServiceCategoryField label="Service category"/>
        </Datagrid>
      </ReferenceManyField>
      </CollapsibleSection>
      <CollapsibleSection title="Diagnoses" initialOpen={false}>
      <ReferenceManyField
        label='Diagnoses'
        reference='visit_diagnoses_summary'
        target='visit_id'
        perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
        pagination={<Pagination />}
      >
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <TextField label='Diagnoses ID' source="diagnosis_id"/>
          <ReferenceField source="diagnosis_id" reference="diagnosis" label="Description">
            <TextField source="description"/>
          </ReferenceField>
          <ReferenceArrayField reference="claim" source="claims" label="Claims">
            <SingleFieldList>
              <ChipField size='small' source="claim_nbr" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField reference="billing_claim" source="billing_claims" label="Billing Claims">
            <SingleFieldList>
              <ChipField size='small' source="claim_nbr" />
            </SingleFieldList>
          </ReferenceArrayField>
        </Datagrid>
      </ReferenceManyField>
      </CollapsibleSection>
        <CollapsibleSection title="Services" initialOpen={false}>
      <ReferenceManyField
        label='Services'
        reference='visit_services_summary'
        target='visit_id'
        perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
        pagination={<Pagination />}
      >
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <TextField label='Service code' source="service_type"/>
          <ReferenceArrayField reference="claim" source="claims" label="Claims">
            <SingleFieldList>
              <ChipField size='small' source="claim_nbr" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField reference="billing_claim" source="billing_claims" label="Billing Claims">
            <SingleFieldList>
              <ChipField size='small' source="claim_nbr" />
            </SingleFieldList>
          </ReferenceArrayField>
        </Datagrid>
      </ReferenceManyField>
        </CollapsibleSection>
    </SimpleShowLayout>
  </Show>
);

export default VisitShow;
