// In theme.js
import { defaultTheme } from "react-admin";
import { createTheme } from '@mui/material/styles';
import { red, lightBlue, amber, green } from '@mui/material/colors';
import merge from "lodash/merge";
import {VITE_THEME_SECONDARY_MAIN} from "./config";

export const theme = createTheme(
  merge({}, defaultTheme, {
    palette: {
      secondary: {
        main: VITE_THEME_SECONDARY_MAIN,
      },
      sapiensSuccess: {
        light: green[50],
        main: green[50],
        dark: green[50],
        contrastText: green[900],
      },
      sapiensWarning: {
        light: amber[50],
        main: amber[50],
        dark: amber[50],
        contrastText: amber[900],
      },
      sapiensInfo: {
        light: lightBlue[50],
        main: lightBlue[50],
        dark: lightBlue[50],
        contrastText: lightBlue[900],
      },
      sapiensError: {
        light: red[50],
        main: red[50],
        dark: red[50],
        contrastText: red[900],
      },
    },
    typography: {
      fontFamily: [
        'Inter'
      ].join(',')
    },
    components: {
      ...defaultTheme.components,
      RaLayout: {
        styleOverrides: {
          root: {
            "& .RaLayout-content": {
              backgroundColor: "white",
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      RaMenuItem: {
        styleOverrides: {
          root: {
            width: "100%",
            backgroundColor: "#fafafa",
            "&:hover": {
              backgroundColor: "#fafafa",
            },
            "& .MuiTypography-root": {
              fontSize: "14px",
            },
            "& .CustomMenuItem": {
              color: "#222323",
              opacity: "0.6",
              "&:hover": {
                opacity: "1.0",
              },
              "& .RaMenuItem-itemButton": {
                padding: "8px 0",
              },
              "& .RaMenuItem-button": {
                "&:hover": {
                  backgroundColor: "#fafafa",
                },
              },
            },
            "& .CustomMenuItemActive": {},
            "& .CustomMenuItemMainActive": {
              width: "calc(100% + 16px)",
              marginLeft: "-16px",
              paddingLeft: "12px",
              borderLeft: "4px solid #6EC0CB",
            },
            "& .CustomMenuItemMainTreeActive": {
              opacity: "1.0",
            },
            "& .CustomMenuSubItemActive": {
              width: "calc(100% + 20px)",
              backgroundColor: "#2223230A",
              borderRadius: "8px",
              padding: "0 30px",
              marginLeft: "-30px",
            },
          },
        },
      },
    },
  })
);
