import * as React from 'react';
import {ReferenceField} from 'react-admin';
import PatientNameTextField from "./PatientNameTextField";


const PatientNameReferenceField = (props) => {
  const link = props.link || "show"
  const label = props.label || "Member"

  return (
    <ReferenceField
      label={label}
      source="patient_id"
      reference="patient"
      link={link}
      {...props}
    >
      <PatientNameTextField variant={props.variant}/>
    </ReferenceField>
  )};

PatientNameReferenceField.defaultProps = {
  source: 'patient_id',
};

export default PatientNameReferenceField;
