import * as React from 'react';
import { RaRecord } from 'ra-core';
import { CustomAutocompleteInput, CustomAutocompleteInputProps } from './CustomAutocompleteInput';

export const CustomAutocompleteArrayInput = <
    OptionType extends RaRecord = RaRecord,
    DisableClearable extends boolean | undefined = boolean | undefined,
    SupportCreate extends boolean | undefined = false
>({
    defaultValue,
    keepInputValue,
    ...props
}: CustomAutocompleteArrayInputProps<
    OptionType,
    DisableClearable,
    SupportCreate
>) => (
    <CustomAutocompleteInput<OptionType, true, DisableClearable, SupportCreate>
        {...props}
        multiple
        defaultValue={defaultValue ?? []}
        disableCloseOnSelect={keepInputValue ?? false}
        blurOnSelect={!keepInputValue}
        keepInputValue={keepInputValue}
    />
);

export type CustomAutocompleteArrayInputProps<
    OptionType extends any = RaRecord,
    DisableClearable extends boolean | undefined = false,
    SupportCreate extends boolean | undefined = false
> = Omit<
    CustomAutocompleteInputProps<OptionType, true, DisableClearable, SupportCreate>,
    'defaultValue'
> & {
    defaultValue?: any[];
    keepInputValue?: boolean;
};
