import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  SingleFieldList,
  ChipField,
  ReferenceManyField,
  ReferenceField,
  useRecordContext,
  TopToolbar,
  EditButton,
  Button, useResourceContext
} from "react-admin";
import BillingClaimPatientField from "./BillingClaimPatientField";
import GatekeeperReferenceField from "../gatekeeper/GatekeeperReferenceField";
import {SapiensFieldsRow} from "../components/SapiensFieldsRow";
import {Link} from "react-router-dom";
import ArrowCircleDown from "@mui/icons-material/ArrowCircleDown";


export const NavigateOriginsButton = (props) => {
  const {
    icon = <ArrowCircleDown />,
    label = 'Origin',
    ...rest
  } = props;
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  if (!record) return null;
  return (
    <Button
      component={Link}
      to={'/' + resource + '/' + record.id + '/origin'}
      label={label}
      onClick={(e) => e.stopPropagation()}
      {...rest}
    >
      {icon}
    </Button>
  );
};

const BillingClaimShowActions = (props) => {
  const record = useRecordContext(props);
  if (!record) return null;
  return (
    <TopToolbar>
      <NavigateOriginsButton/>
      <EditButton />
    </TopToolbar>
  );
}

export const BillingClaimShow = () => (
  <Show actions={<BillingClaimShowActions/>}>
    <SimpleShowLayout>
      <SapiensFieldsRow>
        <ReferenceField source="visit_id" reference="visit" link='show'/>
        <TextField source="claim_nbr" />
        <ReferenceField source="insurance_provider_id" reference="insurance_provider">
          <TextField source="description" />
        </ReferenceField>
        <GatekeeperReferenceField />
        <BillingClaimPatientField label='Member' />
      </SapiensFieldsRow>
      <SapiensFieldsRow>
        <DateField source="service_date" />
        <TextField source="origin_created_by"/>
        <TextField source="origin_created_at"/>
        <TextField source="origin_updated_by"/>
        <TextField source="origin_updated_at"/>
      </SapiensFieldsRow>
      <SapiensFieldsRow>
        <TextField source="billed_date"/>
        <TextField source="billed_status"/>
      </SapiensFieldsRow>
      <ReferenceManyField
        label="Diagnosis"
        reference="billing_claim_diagnosis"
        source="id"
        target="billing_claim_id"
      >
        <SingleFieldList linkType={false} empty={<div>N/A</div>}>
            <ChipField source="diag_code" />
        </SingleFieldList>
      </ReferenceManyField>
      <ReferenceManyField
        label="Services"
        reference="billing_claim_service"
        source="id"
        target="billing_claim_id"
      >
        <SingleFieldList linkType={false} empty={<div>N/A</div>}>
            <ChipField source="procedure" />
        </SingleFieldList>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export default BillingClaimShow;
