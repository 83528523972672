import { useState, useEffect } from 'react';
import {Admin, CustomRoutes, Resource} from 'react-admin';
import {Route} from 'react-router-dom';
import {CognitoAuthProvider} from "ra-auth-cognito";
import * as Sentry from "@sentry/react";

import { useApolloClient } from '@apollo/client';

import gatekeeper from "./gatekeeper";
import provider from "./provider";
import patient from "./patient";
import PatientDiagnosisShow from "./patient/PatientShowDiagnosisTab";
import claim from "./claim";
import query from "./query";
import webuser from "./user";
import audit from "./audit";
import reconciliationReport from "./reconciliationreport";
import reconciliationReportQuery from "./reconciliationreportquery";
import providerPractitioner from "./providerpractitioner";
import PatientShowHCCTab from "./patient/PatientShowHCCTab";
import dataFile from "./datafile";
// import file from "./file";
import insuranceFile from "./insurancefile";
import appointment from "./appointment";
import billing_claim from "./billingclaim";
import billing_claim_cms_claim from "./billingclaimcmsclaim";
import visit from "./visit";
import office from "./office";
import contract from "./contract";
import {theme} from "./theme";
import {buildDataProvider} from "./dataProvider";
import ReconciliationReportAnswers from "./reconciliationreport/ReconciliationReportAnswers";
import ReconciliationReportDev from "./reconciliationreport/ReconciliationReportDev";
import AppointmentBatchAssigment from "./appointment/AppointmentBatchAssigment";
import {VITE_COGNITO_APP_CLIENT_ID, VITE_COGNITO_HOSTED_UI_URL, VITE_COGNITO_USERPOOL_ID} from "./config";
import {CustomLayout} from "./CustomLayout";
import AuditClaimEdit from "./audit/AuditClaimEdit";
import PatientEditSupervisor from "./patient/PatientEditSupervisor";
import ReportsBI from "./reports/ReportsBI";
import AuditClaimShow from "./audit/AuditClaimShow";
import {AuditResultsShow} from "./audit/AuditResultsShow";
import QuicksightReports from "./reports/QuicksightReports";
import {ReconciliationReportProvPractitionerList} from "./reconciliationreport/ReconciliationReportProvPractitionerList";
import gap_patient from "./gap_patient"
import gap from "./gap";
import GapPatientBatchUpdate from "./gap_patient/GapPatientBatchUpdate";
import rule from "./rule";
import ruleCondition from "./rulecondition";
import ruleAction from "./ruleaction";
import BillingClaimShowOrigin from "./billingclaim/BillingClaimShowOrigin";
import MissingInsuranceFileList from "./insurancefile/MissingInsuranceFileList";



const App = () => {
  const [dataProvider, setDataProvider] = useState(null);
  const client = useApolloClient();

  const authProvider = CognitoAuthProvider({
      mode: 'oauth',
      hostedUIUrl: VITE_COGNITO_HOSTED_UI_URL,
      userPoolId: VITE_COGNITO_USERPOOL_ID,
      clientId: VITE_COGNITO_APP_CLIENT_ID,
      scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
  });


  useEffect(() => {
    const buildProvider = async () => {
      const dataProvider = await buildDataProvider(client);
      setDataProvider(dataProvider);
    };
    buildProvider()
  }, []);

  if (!dataProvider) return <p>Loading...</p>;

  setTimeout(() => {
    authProvider.getIdentity()
      .then((identity) => {
        // console.log('Adding user identity to Sentry');
        if (identity) {
          Sentry.setUser({id: identity.id, username: identity.fullName});
        }})
      .catch((reason) => {
        console.log('error getting identity', reason);
      });
  }, 5000);

  return (
    <Admin
      title="inqora"
      dataProvider={dataProvider}
      authProvider={authProvider}
      requireAuth
      layout={CustomLayout}
      theme={theme}
    >
      {permissions => (
        <>
        {permissions.length <= 1 && permissions.includes('prov-practitioner') ?
          <Resource name='reconciliation_report' list={<ReconciliationReportProvPractitionerList/>}>
            <Route path=":id/pdf" element={<ReconciliationReportDev/>}/>
          </Resource> :
          <>
            <Resource name='patient' {...patient}>
              <Route path=":id/hcc" element={<PatientShowHCCTab />}/>
              <Route path=":id/diagnosis" element={<PatientDiagnosisShow />}/>
              <Route path=":id/edit" element={<PatientEditSupervisor />}/>
            </Resource>
            <Resource name='patient_hedis_measure' />
            <Resource name='reconciliation_report' {...reconciliationReport} >
              <Route path="by_patient/:patientId" element={<reconciliationReport.list/>}/>
              <Route path=":id/answers" element={<ReconciliationReportAnswers/>}/>
              <Route path=":id/pdf" element={<ReconciliationReportDev/>}/>
            </Resource>
            <Resource name='reconciliation_report_query' {...reconciliationReportQuery} />
            <Resource name='diagnosis' recordRepresentation={(record) => `${record.description} [${record.id}]`}/>
            <Resource name='claim' {...claim} recordRepresentation={(record) => `${record.id}`}/>
            <Resource name='gatekeeper' {...gatekeeper} />
            <Resource name='hedis_measure'/>
            <Resource name='provider' {...provider} />
            <Resource name='query' {...query} />
            <Resource name='query_kind' />
            <Resource name='provider_practitioner' {...providerPractitioner} />
            <Resource name='data_file' {...dataFile} />
            <Resource name='appointment' {...appointment} >
              <Route path="assigment" element={<AppointmentBatchAssigment/>}/>
            </Resource>
            <Resource name='appointment' {...appointment} />
            <Resource name='audit' {...audit}>
              <Route path=":id/results" element={<AuditResultsShow/>}/>
            </Resource>
            <Resource name='audit_claim' edit={<AuditClaimEdit />} show={<AuditClaimShow/>}/>
            <Resource name='audit_progress'/>
            <Resource name='audit_claim_status'/>
            <Resource name='audit_claim_diagnosis_result'/>
            <Resource name='audit_claim_diagnosis_kind'/>
            <Resource name='diagnosis_condition_v24'/>
            <Resource name='diagnosis_condition_v28'/>
            {/*<Resource name='file' options={{ label: 'Insurance files' }} {...file}/>*/}
            <Resource name='insurance_file' {...insuranceFile}/>
            <Resource name='insurance_file_class'/>
            <Resource name='missing_insurance_file_summary' list={<MissingInsuranceFileList/>}/>
            <Resource name='insurance_file_prefix'/>
            <Resource name='billing_claim' {...billing_claim}>
              <Route path=":id/origin" element={<BillingClaimShowOrigin />}/>
            </Resource>
            <Resource name='billing_claim_origin_row'/>
            <Resource name='billing_claim_cms_claim' {...billing_claim_cms_claim}/>
            <CustomRoutes>
              <Route path="/reports/bi" element={<ReportsBI/>} />
              <Route path="/reports/billing" element={<QuicksightReports/>} />
            </CustomRoutes>
            <Resource name='user' {...webuser}/>
            <Resource name='visit' {...visit}/>
            <Resource name='office' {...office}/>
            <Resource name='contract' {...contract}/>
            <Resource name='gap_patient' {...gap_patient}>
              <Route path=':id/batch_update' element={<GapPatientBatchUpdate/>}/>
            </Resource>
            <Resource name='gap' {...gap}/>
            <Resource name='rule' {...rule}/>
            <Resource name='rule_condition' {...ruleCondition}/>
            <Resource name='rule_action' {...ruleAction}/>
            <Resource name='prx_service_drug'/>
          </>
        }
        </>
      )}
    </Admin>
  );
};

export default App;
