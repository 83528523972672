import {Edit, SaveButton, SimpleForm, TextInput, Toolbar} from "react-admin";

const UserEditToolbar = () => {
  return (
    <Toolbar>
      <SaveButton/>
    </Toolbar>
  )
}

export const UserEdit = () => {
  return (
    <Edit redirect='show'>
      <SimpleForm toolbar={<UserEditToolbar/>}>
        <TextInput source="name" />
        <TextInput source="email" />
      </SimpleForm>
    </Edit>
  );
}

export default UserEdit;
